@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 30px;
    height: 4px;
    background-color: white !important;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}


.passgo-navbar {
    background-color: #0B0062 !important;
    transition: background-color 0.3s ease;
    position: relative;
    overflow: visible;
}

.navbar-special {
    background-color: transparent !important;
    box-shadow: none;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2 !important;
    width: 100%;
}

.navbar-scrolled {
    background-color: #0B0062 !important;
    position: fixed !important;
    top: 0;
    box-shadow: none;
    z-index: 2 !important;
    width: 100%;
    transition: background-color 0.3s ease;
}

.opc-navbar {
    color: white !important;
    font-family: "Inter", sans-serif;
    margin: 0;
    font-weight: 700;
}

.opc-special{
    color: #0B0062 !important;
    font-family: "Inter", sans-serif;
    margin: 0;
    font-weight: 800;
}
.icon-navbar{
    color:white !important;
}
.icon-special{
    color: #0b0062 !important;
}
