/* Inscripcion.css */
.container {
    justify-content: center;
    padding: 20px;
}

.form-wrapper {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
}

.card-custom {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
}

.submit-button {
    width: 150px;
    height: 50px;
    font-size: 1.2rem;
}