.header-public {
    position: relative;
    height: 360px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
}

.contenido-public {
    position: relative;
    z-index: 10;
   /*  display: flex; */
    align-items: baseline;
    padding: 20px 40px 15px 40px;
    height: 70%;
    width: 100%;
}

.imagen-evento {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-right: 24px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 8px 20px;
}

.titulo-evento {
    font-size: 32px;
    font-weight: bold;
    color: white;
    margin-right: auto;
}

.texto-evento {
    color: rgb(255, 255, 255);
    text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
}

.breadcrumb-public {
    position: relative;
    z-index: 10;
    padding-left: 40px;
    margin-right: auto;
}

.container-btn-compra {
    position: sticky;
    bottom: 0px;
    background-color: #f8f8ff;
}

#btn-compra {
    width: 100%;
    color: #fdfdfd;
    background-color: #5656ff;
    
    &:hover {
        background-color: #221a60;
    }
}

/*Por si en la descripcion viene alguna etiqueta que contiene margin, se lo sacamos*/
.descripcion-unstyled * {
    margin: 0;
}

.barra-tarifas {
    width: 10px;
    background-color: #5656ff;
    border-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.barra-tarifas-desktop{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 75%;
    background-color: #5656FF;
    border-radius: 4px;
}
.barra-tarifas-disabled{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 75%;
    background-color: #D9D9D9;
    border-radius: 4px;
}
