.custom-card {
    box-shadow: none !important;
    border: 1px solid #ccc
  }
  
  .custom-card:hover {
    box-shadow: none !important; 
  }

  .custom-summary-card {
    box-shadow: none !important; 
    border: 1px solid #ccc
  }
  
  .custom-summary-card:hover {
    box-shadow: none !important;
  }