.card {
    transition: box-shadow 0.3s ease; 
    border-style: none;
}

.card:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
}


.destacado {
    color: #0B0062;
    font-weight: bold;
}


.help-container {
    max-width: 100%;
    overflow-x: hidden;
}



@media (max-width: 600px) {
    .top-help {
        position: static;
        display: block;
        text-align: center;
        margin-top: 10px;
        transform: none;
    }
}