/* ----------HERO--------- */
.hero-div-crear {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 550px;
    background-image: url("../assets/fondonuevo.svg");
    background-size: cover;
    background-position: bottom;
    position: relative;
    z-index: 1;
}

.hero-title {
    color: #221A60;
}

.hero-h3 {
    text-align: center;
    font-size: 35px;
    font-style: italic;
    color: #221A60;
}

/* --------FIRST SECTION--------- */
.info-first-div {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 40px;
    position: relative;
    background-color: #FFFFFF;
}

.info-first-div-title {
    font-style: italic;
    padding: 10px;
    text-align: center;
    color: #221A60;
    font-weight: bold;
}

.info-first-div-p {
    font-style: italic;
    padding: 10px;
    text-align: center;
    width: 70%;
    font-size: 20px
}

.info-first-div-button {
    background-color: #0B0062;
    color: white;
    justify-self: end;
    font-size: 20px;
    border-radius: 10px;
    padding: 10px 15px;
}

.info-first-div-button:hover {
    background-color: #5656FF;
    color: white;
}

.info-first-div-a {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
}

.info-first-div-a:hover {
    color: white;
    text-decoration: none;
}

/* -------SECOND SECTION -------- */
.info-second-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    background-image: url("../assets/fondonuevo.svg");
    background-size: cover;
    background-position: bottom;
    /* overflow: hidden; */

}

.info-second-div-title {
    color: #221A60;
    /* margin-top: 30px; */
    position: relative;
    bottom: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

/* slider */
.slider-container {
    width: 100%;
    margin: 0 auto;
    /* overflow: hidden; */

}

.slick-list {
    width: 100%;
    max-width: 1200px;
}

.slide {
    padding: 0 15px;
    width: 100%;
    /* max-width: 1200px */
    ;
}

.slide-card {
    background: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 18px;
    height: 280px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.slide-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #221A60;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 13px;
}

.slide-description {
    color: #221A60;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
}

@media (max-width: 1024px) {
    .slide-card {
        min-height: 280px;
        width: 100%;
    }

    .slide {
        width: 100%;
        max-width: 500px;
        height: auto;
    }
}

@media (max-width: 600px) {
    .hero-div-crear img {
        width: 80%;
    }

    .slide-card {
        min-height: 200px;
        width: 100%;
    }

    .slide {
        width: 100%;
        max-width: 350px;
    }
}

@media (max-width: 480px) {
    .slide-card {
        width: 100;
    }
}

.costumer-slider .slick-dots li button:before {
    color: #0B0062;
    font-size: 8px;
}

.costumer-slider .slick-dots li.slick-active button:before {
    color: #0B0062;
    font-size: 8px;
}

/* ------------ COSTUMERS ----------- */
.costumers-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.costumers-div-title {
    color: #221A60;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    text-align: center;
}

.name-costumer {
    font-weight: bold;
    font-size: 22px;
}

.text-div-costumer {
    width: 60%;
    text-align: center;
}

.text-p-costumer {
    color: black;
    font-size: 18px !important;
    font-style: italic;
    text-align: center;
}

.costumer-slider .slick-list {
    max-width: 100%;
}

.costumer-slider .slick-slide {
    display: flex;
    justify-content: center;
    padding: 0 15px;
}

.costumer-slider .slick-dots li button:before {
    color: #0B0062;
    font-size: 8px;
}

.costumer-slider .slick-dots li.slick-active button:before {
    color: #0B0062;
    font-size: 8px;
}

/* -------CONTACT US------- */
.contactus-div {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 20px;
    align-items: center;
    margin-top: 30px;
}

.contactus-div-form {
    flex: 1.5;
    display: flex;
    width: 95%;
    justify-content: center;
    margin-bottom: 30px;
}

.contactus-div-form-form {
    width: 70%;
    margin: 30px;
}

.social-div {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
}

.social-div-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
    flex: 1;
    position: relative;
    width: 100%;
}

.social-img-logo {
    width: 60%;
}

.social-div-redes {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    pad: 4px;
    padding-left: 30px;
    justify-content: space-around;
}

.redes-h5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #221A60;
    font-weight: 600;
    margin-right: 15px;
}

.span-sm {
    font-size: 15px;
    margin-top: -20px;

}

@media (max-width: 500px) {
    .redes-h5 {
        font-size: 16px;
    }

    .social-div-redes {
        padding-left: 10px;
    }
}

@media(max-width: 600px) {
    .info-first-div {
        flex-direction: column;
        align-items: center;
    }

    .contactus-div {
        flex-direction: column;
        align-items: center;
    }

    .contactus-div-form-form {
        width: 100%;
    }

    .contactus-div-form {
        width: 100%;
    }

    .social-div-redes {
        flex: 1.5;
    }


}

@media(max-width: 700px) {
    .info-second-div-h3 {
        font-size: 30px;
    }

    .info-second-div-p {
        font-size: 16px;
    }

    .info-second-div {
        height: 400px;
    }

    .social-img-logo {
        width: 80%;
    }

}