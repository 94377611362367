@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif !important;
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

p{
  font-family: "Inter", sans-serif !important;
}

code {
  font-family: "Inter", sans-serif;
}

button{
  border: none;
  background-color: transparent;
}

/*Centra los datos de las filas en el medio de forma vertical*/
table tr td {
  vertical-align: middle !important;
}

/*Corregir la paginacion de la libreria MUI React*/
.MuiTablePagination-toolbar {
  padding: 0 !important;
}

.MuiTablePagination-toolbar p {
  margin: 0;
  align-items: center;
}

/*Estilo del th tabla MUI React*/
.MuiTableCell-head {
  font-weight: bold !important;
}

/*Quitar las mayusculas por defecto de los botones*/
.MuiButton-root {
  font-size: 1rem !important;
  text-transform: none !important;
  height: 2.5rem;
}

.circulo-estado {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: .2rem;
  animation: pulsar 1.5s infinite; /* Llama a la animación pulsar */
}

/* Definición de la animación */
@keyframes pulsar {
  
  50% {
    box-shadow: 0 0 10px var(--color); /* Sombra con color dependiente del estado */
  }
}