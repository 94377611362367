.cookie-preferences {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cookie-preferences h3 {
    margin: 0 0 20px 0;
    font-size: 18px;
    text-align: center;
}

.cookie-preferences .options-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.cookie-preferences label {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 16px;
    gap: 8px;
}

.cookie-preferences input[type="checkbox"] {
    margin: 0;
}

.cookie-preferences button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cookie-preferences button:hover {
    background-color: #0056b3;
}

.cookie-preferences button:focus {
    outline: none;
}