.notifications-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
    font-family: Arial, sans-serif;
}


.card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card:hover {
    transform: none !important;
    box-shadow: none !important;
    transition: none !important;
}

.card-header {
    padding: 1.5rem;
    border-bottom: 1px solid #e0e0e0;
    border: 1px solid #e0e0e0;
}

.card-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin: 0;
}

.card-content {
    padding: 1.5rem;
    border: 1px solid #e0e0e0;
}

#notifications-tabs {
    display: flex;
    margin-bottom: 1.5rem;
}

.tab {
    flex: 1;
    padding: 0.75rem;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #e0e0e0;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: all 0.3s ease;
}

.tab.active {
    border-bottom-color: #007bff;
    color: #007bff;
}

.alert {
    background-color: #e0f7fa;
    color: #00838f;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.notification-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 1rem;
    transition: all 0.3s ease;
}


/* .notification-card:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

.notification-content {
    flex-grow: 1;
    margin-right: 1rem;
}

.notification-title {
    font-size: 1.45rem;
    font-weight: bold;
    margin: 0 0 0.5rem 0;
}

.notification-description {
    font-size: 0.9rem;
    color: #666;
}

.notification-date {
    font-size: 0.8rem;
    color: #888;
    display: flex;
    align-items: center;
}


.notification-actions {
    display: flex;
    flex-direction: column;
}

.btn-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    padding: 0rem;
    color: inherit;
}


.notification-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notification-actions .btn-icon.bell-icon {
    color: #007bff;
}

.notification-actions .btn-icon.trash-icon {
    color: #888;
}

.empty-notifications {
    text-align: center;
    padding: 2rem;
}


.empty-image {
    max-width: 350px;
    margin-bottom: 1rem;
}


.empty-message {
    font-size: 1.2rem;
    color: #666;
}

@media (max-width: 650px) {
    .empty-image {
        max-width: 0;
    }
}