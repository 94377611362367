@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}


.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.Toastify__toast--success {
  background-color: rgba(12, 133, 38, .9);
  backdrop-filter: blur(8px);
  color: #fff;
}

.Toastify__toast--error {
  background-color: rgba(177, 17, 33, 0.9); 
  backdrop-filter: blur(8px);
  color: #fff;
}

.Toastify__toast--warning {
  background-color: rgba(200, 151, 4, 0.9);
  backdrop-filter: blur(8px);
  color: #fff;
}


.Toastify__toast--info {
  background-color: rgba(78, 192, 209, 0.9);
  backdrop-filter: blur(8px);
  color: #fff;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}