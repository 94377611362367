@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.button-ads-desktop {
  cursor: pointer;
  background-color: #F5F5F5;
  border-radius: 15px;
  /* padding: .5rem 1.5rem; */
  width: 150px;
  height: 120px;
  transition: background-color 0.3s ease;
}

.button-ads-desktop:hover {
  background-color: #C2DAFF !important;
}

.button-ads-desktop.active {
  background-color: #C2DAFF;
}

.icon-category {
  color: #0B0062;
  width: 48px !important;
  height: 48px !important;
  font-weight: 300 !important;
}

.text-btn-category {
  font-size: 14px;
  color: #0B0062;
  margin-bottom: 0;
  margin-top: 5px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.container-calendar input {
  position: relative;
  bottom: 1px;
  background-color: transparent;
  height: 56px !important;
  /* border-radius: 4px; */
  border: 1px solid #C4C4C4;
  padding: 3px 20px;
  outline: none;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}

.rmdp-input {
  border-radius: 0 !important;
}


.container-calendar input:focus {
  border: 2px solid #1770C8 !important; 
}

.date-picker {
  background-color: #E4E4E4;
}

.boton-buscar-filtros{
  color: white;
  font-weight: bold;
  height: 56px !important;
  width: 13%;
  padding: 5px 8px;
  /* border: 1px solid #C4C4C4; */
  background-color: #5656FF;
  border-radius: 0 10px 10px 0;
  font-family: "Inter", sans-serif;
}

.boton-buscar-filtros:hover{
  background-color: #16068d;
}
.boton-limpiar-filtros{
  color: red;
  background-color: transparent;
  outline: none;
  font-weight: normal;
  margin-left: 15px;
  font-size: 14px;
}
.content-boton-filtros{
  margin-top: auto;
}
.txt-boton-filtros{
  font-family: "Inter", sans-serif;
}

.input-date-home {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  text-align: center;
  height: 2.5em;
  border: 1px solid transparent;
  cursor: pointer;
}

.input-date-home:focus {
  outline: 1px solid rgb(10, 88, 194) !important;
}

::-webkit-calendar-picker-indicator {
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.btn-ad:hover {
  background: #C2DAFF !important;
}

.btn-ad-more:hover {
  background: transparent !important;
  color: #0d6efd !important;
}

/***************************************************************************************************/


.category-button-mobile {
  background: none;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  color: #000;
  transition: color 0.3s, border-bottom 0.3s;
}

.category-button-mobile:hover {
  color: #007bff;
}

.category-button-mobile.active {
  border-bottom: 2px solid #000;
  color: #000;
}

.date-component {
  margin-right: 20px;
}

.container-calendar-mobile {
  margin-top: 10px;
  display: flex;
  color: red;
  justify-content: center;
}

.container-calendar-mobile input {
  background-color: transparent;
  color: black;
  border-radius: 10px;
  width: 100%;
}

.container-calendar-mobile input::placeholder {
  color: #fff;
}






.card-publicacion {
  width: 280px;
  height: 380px;
  display: flex;
  flex-direction: column;
}

.media-publicacion {
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
}

.contenido-publicacion {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 7px !important;
  /* padding-bottom: 16px !important; */
}

.title-publicacion {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin: 5px 0 5px 0;
  line-height: 1.2;
}

.text-publicacion {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.fechaHora-publicacion {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 5px;
}

.descripcion {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  margin-top: 5px;
}

.contenedor-badge {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 10px;
  border-radius: 8px;
}

.contenedor-badge small{
  font-weight: 500;
}

.boton-publicacion{
  outline: none;
  border-radius: 10px;
  background-color: #5656FF;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #F5F5F5;
  padding: 6px 15px;
  text-align: center;
}
.boton-publicacion:hover{
  background-color: #16068d;
}

.separador-horizontal {
  width: 100%; 
  border-bottom: 1px solid #D3D3D3; 
  margin: 10px 0; 
  margin-bottom: 20px;
}

.input-region{
  width: 15%;
  height: 56px;
}