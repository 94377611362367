.custom-container {
    margin-left: 10px;
    margin-top: 20px;
    padding: 20px;
    max-width: 600px;
    border: none;
}

.custom-card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    border: none;
}

.custom-card-content {
    padding: 20px;
}

.custom-item {
    background-color: #f1f3f5;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-id-container {
    text-align: center;
    margin-bottom: 10px;
}

.custom-id {
    color: #343a40;
    font-weight: 500;
}

.custom-divider {
    border: none;
    border-top: 2px solid #6c757d;
    width: 100%;
    margin: 20px 0;
}

.custom-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 10px;
}

.custom-label {
    color: #343a40;
    font-weight: 500;
    margin-right: 10px;
}

.custom-value {
    color: #343a40;
    font-weight: 400;
    text-align: right;
    flex-grow: 1;
}

.custom-footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}