.card-pago{
    display: flex;
    align-items: center;
    width: 150px;
    min-height: 100px;
    justify-content: center;
    object-fit: cover;
    cursor: pointer;
}
.card-pago:hover{
    background-color: #F0F0F1;
}
.card-pago:focus-within{
    background-color: red !important;
}

.img-card-pago{
    width: 80px;
    height: 75px;
}
.img-card-pago-binance{
    width: 60px;
    height: 50px;
}
.icon-card-pago{
    font-size: 45px;
    text-align: center;
}
.txt-card-pago{
    font-size: 14px;
    text-align: center;
}

.vinculacion:hover {
    background-color: #a3a3a360 !important;
    transform: scale(.98);
    transition: all .3s;
}