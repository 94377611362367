@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 500px;
  background-image: url("../../assets/fondonuevo.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 /*  overflow: hidden; */
}

.contenido-header {
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: calc(100% - 20px);
  /* padding: 0 20px; */
  background-color: white;
  border-radius: 15px
    /* 15px 0 0 */
  ;
}

.carousel-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 100%;
  flex-direction: row-reverse;
}

.img-header {
  width: 65%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.img-header img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 15px;
}

.text-header {
  width: 45%;
  color: #0B0062;
  padding: 10px;
  text-align: left;
}

.title-header {
  font-size: 48px !important;
  font-family: "Inter", sans-serif;
  margin: 0;
  font-weight: 700;
}

.fecha-header {
  font-family: "Inter", sans-serif;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(128, 128, 128, 0.7);
  border-radius: 50%;
  padding: 10px;
}

.boton-carousel:hover {

  background-color: #EBEBEB !important;
}

.carousel-control.left:hover {
  /*  border: 1px solid red !important; */
  background-color: transparent !important;
}

.carousel-control.right {
  /*  right: 10px; */
  background-color: transparent !important;
}



/***************************************************************************************/

.header-container-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.mobile-background-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  text-align: center;
}

.mobile-background-blur {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  filter: blur(1px);
  transition: filter 0.3s ease;
  transform: scale(1.02);
}

.text-header-mobile {
  position: relative;
  z-index: 1;
  width: 100%;
  margin-bottom: 30px;
}

.mobile-background {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  text-align: center;
  border-radius: 0 0 10px 10px;
}

.mobile-title {
  color: white;
  text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.6);
  font-size: 38px;
  margin: 25px 0;
  font-weight: bold;
}

/* slider */
.header-slider {
  width: 100%;
}

.header-slide {
  width: 100%;
  height: 400px;
}

.header-slider .slick-dots {
  bottom: 10px;
}

.header-slider .slick-dots li button:before {
  color: white;
}

.header-slider .slick-dots li.slick-active button:before {
  color: white;
}

.slick-slide {
  opacity: 1;
}

