
.container-evento{
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    
}
#crear-evento{
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
    align-content: center;
    /* align-self: center; */
}
#edit-evento{
  display: flex;
  flex-direction: column !important;
  flex-wrap: wrap;
  align-content: center;
  /* align-self: center; */
}

.card-form-evento{
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
    width: 500px;
    transition: all 0.3s ease;
}
.card-form-evento:hover{
    border: 1px solid #3659E3;
}
.card-form-evento:focus{
    border: 1px solid #3659E3;
}
.card-form-evento.expanded {
    background-color: #f5f5f5;
    cursor: auto;
}
.hidden {
    display: none;
}
.contenido-evento{
    display: block;
}
.contenido-evento.expanded{
    display: none;
}

.btn-enviar-evento{
    padding: 10px 20px;
    display: flex;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 50px;
}

.title-open-card{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}
.txt-open-card{
  font-size: 13px;
  margin-bottom: 4px;
}
.label-open-card{
    font-size: medium;
    font-weight: bold;
    margin-bottom: 5px;
}
.titles-datelocation{
    display: flex;
    justify-content: space-between;
}
.title-datelocation{
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .container-evento {
      padding: 10px;
    }
  
    .card-form-evento {
      /* padding: 10px; */
      width: 100%;
    }
  
    .btn-form-edit{
      gap: 50px !important;
    }
    .titles-datelocation {
      flex-direction: row;
      justify-content: space-around;
    }
  
    .title-datelocation {
      margin-bottom: 15px;
    }
  
    .vr {
      display: none;
    }
  
    /* .d-flex {
      flex-direction: column;
      gap: 10px;
    } */
  
    .title-card-evento {
      font-size: 1.25rem;
    }
  
    .txt-card-evento {
      font-size: 0.9rem;
    }
  
    .gap-3 {
      gap: 15px;
    }
  }

  .inputFile{
    background-color: #ffff;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
 .uploaded-media{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
 }
.renglon-media{
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #EDEDED;
  border-top: 1px solid #EDEDED;
}
.text-preview{
  display: flex;
  flex-direction: column;
}
.title-preview{
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 0;
}
.content-preview{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-preview {
   display: flex;
   justify-content: end;
}
  .media-preview{
    display: flex;
  }
  .image-container {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }
  
  .event-image {
    max-width: 200px;
    max-height: 200px;
  }
  
  .btn-enviar-evento {
    margin-top: 20px;
  }


  .ul-input-img{
    display: flex;
    flex-direction: row;
  }
  .ul-img{
    display: flex;
    gap: 20px;
    padding-left: 20px;
  }

  .btn-form-edit{
    display: flex;
    gap: 300px;
    justify-content: center;
  }